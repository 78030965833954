export const loadSnackbar = (
  message = "Something went wrong!",
  type = "error"
) => ({
  type: "LOAD_SNACKBAR",
  payload: {
    message,
    type: type,
    open: true,
  },
});
